exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".partnerContact___1aDn8 {\n  padding: 30px;\n  margin-bottom: 30px;\n  width: 100%;\n}\n@media screen and (min-width: 600px) {\n  .partnerContact___1aDn8 {\n    width: calc(100% - 20px);\n    max-width: 350px;\n    position: absolute;\n    right: 0;\n  }\n}\n.partnerContact___1aDn8 h3 {\n  margin-top: 0;\n  margin-bottom: 30px;\n}\n.partnerContact___1aDn8 div {\n  display: flex;\n  margin-top: 20px;\n  word-break: break-all;\n  font-size: 14px;\n}\n.partnerContact___1aDn8 div svg {\n  margin-right: 10px;\n}\n.partnerContactGrid___2IcZN {\n  position: relative;\n}\n@media screen and (min-width: 600px) {\n  .partnerContactGrid___2IcZN {\n    padding-left: 20px;\n  }\n}\n", "", {"version":3,"sources":["C:/agent4/vsts-agent-win-x64-2.153.2/_work/1813/s/src/Crayon.EndCustomerPortal/ClientApp/src/components/Home/Home.module.less"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,YAAY;CACb;AACD;EACE;IACE,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,SAAS;GACV;CACF;AACD;EACE,cAAc;EACd,oBAAoB;CACrB;AACD;EACE,cAAc;EACd,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;CACjB;AACD;EACE,mBAAmB;CACpB;AACD;EACE,mBAAmB;CACpB;AACD;EACE;IACE,mBAAmB;GACpB;CACF","file":"Home.module.less","sourcesContent":[".partnerContact {\n  padding: 30px;\n  margin-bottom: 30px;\n  width: 100%;\n}\n@media screen and (min-width: 600px) {\n  .partnerContact {\n    width: calc(100% - 20px);\n    max-width: 350px;\n    position: absolute;\n    right: 0;\n  }\n}\n.partnerContact h3 {\n  margin-top: 0;\n  margin-bottom: 30px;\n}\n.partnerContact div {\n  display: flex;\n  margin-top: 20px;\n  word-break: break-all;\n  font-size: 14px;\n}\n.partnerContact div svg {\n  margin-right: 10px;\n}\n.partnerContactGrid {\n  position: relative;\n}\n@media screen and (min-width: 600px) {\n  .partnerContactGrid {\n    padding-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"partnerContact": "partnerContact___1aDn8",
	"partnerContactGrid": "partnerContactGrid___2IcZN"
};