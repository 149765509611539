exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PageContainer___1O7CY {\n  max-width: 1400px;\n  margin: 20px auto;\n  padding: 16px;\n  position: relative;\n}\n@media screen and (min-width: 600px) {\n  .PageContainer___1O7CY {\n    padding: 24px;\n  }\n}\n", "", {"version":3,"sources":["C:/agent4/vsts-agent-win-x64-2.153.2/_work/1813/s/src/Crayon.EndCustomerPortal/ClientApp/src/components/Page/Page.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;CACpB;AACD;EACE;IACE,cAAc;GACf;CACF","file":"Page.module.less","sourcesContent":[".PageContainer {\n  max-width: 1400px;\n  margin: 20px auto;\n  padding: 16px;\n  position: relative;\n}\n@media screen and (min-width: 600px) {\n  .PageContainer {\n    padding: 24px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"PageContainer": "PageContainer___1O7CY"
};